import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target iframeTarget: HTMLIFrameElement;

  reload() {
    this.iframeTarget.contentWindow?.location.reload();
  }
}
