import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class HideableController extends Controller {
  @Target overlayTarget: HTMLElement;

  toggle() {
    this.overlayTarget.classList.toggle("tw-hidden");
  }
}
