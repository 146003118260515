import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
// well ideally we'd load nested routes here too and exclude admin like we had, but that wasn't working for some reason
const controllers = import.meta.globEager("../controllers/*_controller.@(ts|js)");
const componentControllers = import.meta.globEager("../../components/**/(*_)?controller.@(ts|js)");

registerControllers(application, controllers);
registerControllers(application, componentControllers);
