import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDivElement> {
  @Target readonly checkTarget!: HTMLElement;
  @Target readonly crossTarget!: HTMLElement;

  @Value(String) ruleValue!: string;

  setValidity(valid: boolean) {
    this.checkTarget.classList.toggle("tw-hidden", !valid);
    this.crossTarget.classList.toggle("tw-hidden", valid);

    this.element.classList.toggle("tw-text-lime-600", valid);
  }
}
