import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import { toggleDisableField } from "~/lib/toggleDisabledField";

@TypedController
export default class extends Controller {
  @Target selectContainerTarget!: HTMLDivElement;
  @Target customContainerTarget!: HTMLDivElement;

  @Value(String) customLabelValue!: string;
  @Value(String) selectLabelValue!: string;

  toggle(event: any) {
    let text: string;
    if (this.customContainerTarget.classList.contains("tw-hidden")) {
      this.customContainerTarget.classList.remove("tw-hidden");
      this.selectContainerTarget.classList.add("tw-hidden");

      toggleDisableField(this.selectInput!, false);
      toggleDisableField(this.customInput!, true);

      text = this.customLabelValue;
    } else {
      this.selectContainerTarget.classList.remove("tw-hidden");
      this.customContainerTarget.classList.add("tw-hidden");

      toggleDisableField(this.selectInput!, true);
      toggleDisableField(this.customInput!, false);

      text = this.selectLabelValue;
    }

    event.target!.innerText = text;
  }

  get customInput() {
    return this.customContainerTarget.querySelector("input");
  }

  get selectInput() {
    return this.selectContainerTarget.querySelector("select");
  }
}
