import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLInputElement> {
  @Value(String) loadingLabelValue!: string;

  connect() {
    this.element.addEventListener("click", (event) => {
      if (!this.element.form) return;

      if (this.element.form.checkValidity()) {
        if (this.loadingLabelValue) {
          this.element.value = this.loadingLabelValue;
        }

        this.element.disabled = true; // this cancels the default event
      }

      this.element.form?.requestSubmit();
    });
  }
}
