import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly showPasswordTarget!: HTMLElement;
  @Target readonly eyeClosedTarget!: HTMLElement;
  @Target readonly eyeOpenedTarget!: HTMLElement;

  connect() {
    this.showPasswordTarget.addEventListener("click", this.togglePasswordVisibility.bind(this));
  }

  togglePasswordVisibility() {
    const passwordInputTarget = this.showPasswordTarget.closest("div")?.querySelector("input");
    if (!passwordInputTarget) throw new Error("Password input not found");

    if (passwordInputTarget.type === "password") {
      passwordInputTarget.type = "text";
      this.eyeClosedTarget.classList.add("tw-hidden");
      this.eyeOpenedTarget.classList.remove("tw-hidden");
    } else {
      passwordInputTarget.type = "password";
      this.eyeClosedTarget.classList.remove("tw-hidden");
      this.eyeOpenedTarget.classList.add("tw-hidden");
    }
  }
}
