import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import { toggleDisableField } from "~/lib/toggleDisabledField";

@TypedController
export default class extends Controller {
  @Target readonly userAttributeTarget!: HTMLInputElement;
  @Target readonly userAttributeDisableCustomTarget!: HTMLInputElement;

  @Target readonly customAttributeTarget!: HTMLInputElement;
  @Target readonly customAttributeDisableNameTarget!: HTMLInputElement;

  on_change(event: Event) {
    const target = event.target as HTMLInputElement;
    this.disable_fields(target.value as any);
  }

  private disable_fields(target: "user_attribute" | "custom_attribute") {
    const user = target === "user_attribute";

    this.disableField(this.userAttributeTarget, !user);
    this.disableField(this.userAttributeDisableCustomTarget, !user, false);

    this.disableField(this.customAttributeTarget, user);
    this.disableField(this.customAttributeDisableNameTarget, user, false);
  }

  private disableField(field: HTMLInputElement, disable: boolean, required = true) {
    toggleDisableField(field, !disable, {
      required,
      disabledAddClasses: ["tw-hidden"],
    });
  }
}
