import { Target, Targets, TypedController, Value } from "@vytant/stimulus-decorators";
import { addSeconds, formatDistanceStrict } from "date-fns";
import { de, enGB, fr, nlBE, uk, zhCN } from "date-fns/locale";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLFormElement> {
  @Targets readonly digitTargets: HTMLInputElement[];
  @Target readonly invalidCodeMessageTarget: HTMLElement;
  @Target readonly resendAgainButtonTarget: HTMLButtonElement;
  @Target readonly resendAgainTextTarget: HTMLElement;
  @Target readonly formTarget: HTMLFormElement;
  @Target readonly spinnerTarget: HTMLElement;
  @Target readonly remainingTimeLockedMessageTarget: HTMLElement;

  @Value(Number) readonly digitCountValue: number = 4;
  @Value(Number) readonly resendAgainTimeValue: number = 30000;
  @Value(Number) secondsRemainingLockedValue = 0;
  @Value(String) currentLanguageValue = "de";

  connect() {
    document.addEventListener("paste", this.codePasted.bind(this));

    if (this.secondsRemainingLockedValue > 0) {
      this.disabledInputsAndCountdown();
      this.setRemainingTimeLockedMessage();

      setTimeout(() => this.enableResend(), this.resendAgainTimeValue);
    }
  }

  disabledInputsAndCountdown() {
    this.removeInvalidCodeErrorMessage();
    this.setInputsDisabledAttributeTo(true);

    const interval = setInterval(() => {
      this.secondsRemainingLockedValue = this.secondsRemainingLockedValue - 1;

      if (this.secondsRemainingLockedValue <= 0) {
        clearInterval(interval);
        this.setInputsDisabledAttributeTo(false);
        this.digitTargets[0].focus();
        this.remainingTimeLockedMessageTarget.classList.add("hidden");
      }

      this.setRemainingTimeLockedMessage();
    }, 1000);
  }

  digitEntered(event: any) {
    const index = Number((event.target as HTMLElement).dataset.index);
    if (event.key === "Enter") {
      event.preventDefault();
      this.submit();

      return;
    }

    switch (event.key) {
      case "Backspace":
      case "Delete":
        event.preventDefault();

        // @ts-expect-error IDK WHY
        this.digitTargets[index].value = null;

        if (index > 0) {
          this.digitTargets[index - 1].focus();
        } else {
          this.digitTargets[index].focus();
        }
        return;
      case "ArrowLeft":
        event.preventDefault();

        if (index > 0) {
          this.digitTargets[index - 1].focus();
        }
        return;
      case "ArrowRight":
        event.preventDefault();

        if (index < this.digitCountValue - 1) {
          this.digitTargets[index + 1].focus();
        }
        return;
      default:
        if (!/^[0-9]$/i.test(event.key)) {
          return;
        }

        event.preventDefault();

        if (index < this.digitCountValue) {
          this.digitTargets[index].value = event.key;
        }

        if (index + 1 < this.digitCountValue) {
          this.digitTargets[index + 1].focus();
        }

        if (index + 1 === this.digitCountValue) {
          this.submit();
        }
    }

    this.removeInvalidCodeErrorMessage();
  }

  codePasted(event: ClipboardEvent) {
    event.preventDefault();

    if (this.secondsRemainingLockedValue > 0) {
      return;
    }

    this.removeInvalidCodeErrorMessage();
    const pastedCode = event.clipboardData?.getData("text").slice(0, this.digitCountValue) || "";
    for (let i = 0; i < this.digitCountValue; i++) {
      if (pastedCode[i] === undefined) {
        break;
      }

      if (!/^[0-9]$/i.test(pastedCode[i])) {
        continue;
      }

      this.digitTargets[i].value = pastedCode[i];
    }

    if (pastedCode.length === this.digitCountValue) {
      this.submit();
    }
  }

  removeInvalidCodeErrorMessage() {
    this.digitTargets.forEach((digitTarget) => {
      digitTarget.classList.remove("border-red-500");
    });

    this.invalidCodeMessageTarget.classList.add("hidden");
  }

  enableResend() {
    this.resendAgainButtonTarget.disabled = false;
    this.resendAgainTextTarget.classList.remove("opacity-40");
  }

  submit() {
    this.spinnerTarget.classList.remove("hidden");
    this.formTarget.submit();
    this.setInputsDisabledAttributeTo(false);
  }

  setInputsDisabledAttributeTo(state: boolean) {
    this.digitTargets.forEach((digitTarget) => {
      digitTarget.disabled = state;
    });
  }

  setRemainingTimeLockedMessage() {
    const timeSpan = this.remainingTimeLockedMessageTarget.querySelector("#remaining-time");

    const time = addSeconds(new Date(), this.secondsRemainingLockedValue);
    timeSpan!.innerHTML = formatDistanceStrict(new Date(), time, { locale: this.dateFnsLocale(this.currentLanguageValue) });
  }

  dateFnsLocale(lang: string) {
    switch (lang) {
      case "de":
        return de;
      case "en":
        return enGB;
      case "fr":
        return fr;
      case "nl":
        return nlBE;
      case "uk":
        return uk;
      case "zh":
        return zhCN;
      default:
        return de;
    }
  }
}
