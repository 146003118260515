import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import { PassedTranslations } from "~/lib/passedTranslations";

import RequirementController from "./requirement_controller";

@TypedController
export default class extends Controller {
  @Target readonly fieldTarget!: HTMLInputElement;
  @Target readonly progressTarget!: HTMLSpanElement;
  @Target readonly progressTextTarget!: HTMLSpanElement;

  @Value(String) requirementsValue!: string;
  @Value(String) translationsValue!: string;

  // I hate naming in this...
  static outlets = ["form--field--password-with-validation--requirement"];
  private declare readonly formFieldPasswordWithValidationRequirementOutlets: RequirementController[];

  private translations: PassedTranslations;

  readonly RULES = {
    min_length: (value: string, count: number) => value.length >= count,
    NUMBER: (value: string) => value.match(/\d/) !== null,
    MIXED_CASE: (value: string) => value.match(/[a-z]/) !== null && value.match(/[A-Z]/) !== null,
    SYMBOL: (value: string) => value.match(/[^a-zA-Z0-9]/) !== null,
  };

  connect(): void {
    this.fieldTarget.addEventListener("input", this.validate.bind(this));
    this.getSubmitButton().disabled = true;

    this.translations = new PassedTranslations(this.translationsValue);

    // safari autofill fix
    this.fieldTarget.value && this.validate.apply(this);
  }

  validate() {
    const value = this.fieldTarget.value;

    const errors = this.jsonRequirements()
      .map(([rule, { message, count }]) => ({ rule, message, ok: this.RULES[rule](value, count) }))
      .map((requirement) => {
        const reqField = this.formFieldPasswordWithValidationRequirementOutlets.find((elem) => elem.ruleValue === requirement.rule);
        reqField?.setValidity(requirement.ok);

        return !requirement.ok && requirement.message;
      })
      .filter(Boolean);

    if (errors.length > 0) {
      this.getSubmitButton().disabled = true;
      this.fieldTarget.setCustomValidity(errors.join(", "));

      this.progressTarget.style.setProperty("--progress", "5%");
      this.progressTarget.style.setProperty("--progress-color", "red");
      this.progressTextTarget.textContent = this.translations.t("strength.not_valid");
      this.progressTextTarget.style.setProperty("--progress-color", "red");
    } else {
      this.getSubmitButton().disabled = false;
      this.fieldTarget.setCustomValidity("");

      const progress = this.calculateProgress(value);
      this.progressTarget.style.setProperty("--progress", `${progress}%`);
      this.progressTarget.style.setProperty("--progress-color", this.progressColor(progress));
      this.progressTextTarget.textContent = this.progressText(progress);
      this.progressTextTarget.style.setProperty("--progress-color", this.progressColor(progress));
    }
  }

  private getSubmitButton(): HTMLButtonElement {
    return this.fieldTarget.closest("form")?.querySelector("input[type=submit]")!;
  }

  private calculateProgress(value: string) {
    if (value === "") {
      return 0;
    }

    const scope = [
      { re: /[a-z]/, length: 26 },
      { re: /[A-Z]/, length: 26 },
      { re: /[0-9]/, length: 10 },
      { re: /[^a-zA-Z0-9]/, length: 33 },
    ].reduce((progress, { re, length }) => {
      if (value.match(re)) {
        return progress + length;
      }

      return progress;
    }, 0);

    return (Math.round((value.length * Math.log(scope)) / Math.LN2) / 120) * 100;
  }

  private progressColor(value: number) {
    if (value === 0) {
      return "#adadad";
    }
    if (value < 30) {
      return "red";
    }
    if (value < 50) {
      return "orange";
    }
    if (value < 80) {
      return "#0093ff";
    }
    return "green";
  }

  private progressText(value: number) {
    if (value === 0) {
      return this.translations.t("strength.empty");
    }
    if (value < 30) {
      return this.translations.t("strength.very_weak");
    }
    if (value < 50) {
      return this.translations.t("strength.weak");
    }
    if (value < 80) {
      return this.translations.t("strength.strong");
    }
    return this.translations.t("strength.very_strong");
  }

  private jsonRequirements(): [string, { message: string; count?: number }][] {
    try {
      return JSON.parse(this.requirementsValue);
    } catch (e) {
      return [];
    }
  }
}
