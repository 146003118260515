/** Disable or enable a field (form=none, disable=true, required=nil) */
export const toggleDisableField = (
  field: HTMLInputElement | HTMLSelectElement,
  enabled: boolean,
  config: Partial<{
    required: boolean;
    disabledRemoveClasses: string[];
    disabledAddClasses: string[];
  }> = {},
) => {
  if (enabled) {
    field.removeAttribute("form");
    config.required && field.setAttribute("required", "required");
    field.removeAttribute("disabled");

    if (config.required) {
      field.classList.remove("optional");
      field.classList.add("required");
    }

    config.disabledAddClasses?.forEach((className) => field.classList.remove(className));
    config.disabledRemoveClasses?.forEach((className) => field.classList.add(className));
  } else {
    field.setAttribute("form", "none");
    field.removeAttribute("required");
    field.setAttribute("disabled", "disabled");

    field.classList.add("optional");
    field.classList.remove("required");

    config.disabledAddClasses?.forEach((className) => field.classList.add(className));
    config.disabledRemoveClasses?.forEach((className) => field.classList.remove(className));
  }
};
