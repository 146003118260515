import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class AhoyController extends Controller<HTMLButtonElement | HTMLAnchorElement> {
  @Value(String) eventValue!: string;
  @Value(String) propertiesValue: string | undefined = undefined;

  track() {
    window.ahoy.track(this.eventValue, this.props);
  }

  private get props(): Record<string, string> | undefined {
    try {
      return JSON.parse(this.propertiesValue || "{}");
    } catch (e) {
      return undefined;
    }
  }
}
