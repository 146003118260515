import { patch } from "@rails/request.js/src/verbs";
import SortableController from "stimulus-sortable";
import { Controller } from "stimulus";

export default class extends (SortableController as typeof Controller) {
  private declare readonly resourceNameValue: string | null;
  private declare readonly paramNameValue: string;

  async onUpdate(args: any) {
    const { item, newIndex } = args;
    if (!item.dataset.sortableUpdateUrl) return;

    const positionOfTheOldElement = this.element.querySelector<HTMLTableRowElement>(`[data-sortable-index="${newIndex}"]`);
    if (!positionOfTheOldElement) return;

    const position = positionOfTheOldElement.dataset.sortablePosition;
    if (position === undefined) return;

    const resourceKey = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue;
    const data = new FormData();

    data.append(resourceKey, position);

    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: "json", redirect: "manual" });

    window.location.reload();
  }
}
