import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target selectTarget!: HTMLSelectElement;

  async onChange(_event: Event) {
    const res = await fetch(`/language?language=${this.selectTarget.value}`, {
      redirect: "manual",
      headers: {
        Accept: "application/json",
      },
    });

    if (res.ok) {
      window.location.reload();
    }
  }
}
