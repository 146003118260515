import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDivElement> {
  @Value(Number) readonly timeValue = 5000;

  connect() {
    setTimeout(() => {
      this.element.classList.remove("hidden");
    }, this.timeValue);
  }
}
