import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target detailsTarget!: HTMLDetailsElement;

  on_toggle(event) {
    const ids = this.getIds();
    if (event.target.open) {
      this.setIds([...ids, this.detailsTarget.dataset.id]);
    } else {
      this.setIds(ids.filter((id) => id !== this.detailsTarget.dataset.id));
    }
  }

  private setIds(ids: string[]) {
    const val = JSON.stringify([...new Set(ids)]);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("open", val);

    window.history.replaceState({}, "", `${window.location.pathname}?${urlParams.toString()}`);
    document.querySelectorAll("input[name='open']").forEach((elem: HTMLInputElement) => {
      elem.value = val;
    });
  }

  private getIds() {
    const currentHash = new URLSearchParams(window.location.search);
    try {
      return JSON.parse(currentHash.get("open") || "[]");
    } catch (e) {
      return [];
    }
  }
}
