import { Target, Targets, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Targets readonly checkboxTargets!: HTMLInputElement[];
  @Target readonly valuesContainerTarget!: HTMLDivElement;

  connect() {
    this.checkboxTargets.forEach((checkboxTarget) => {
      checkboxTarget.addEventListener("change", () => {
        const inputs = this.checkboxTargets
          .filter((c) => c.checked)
          .map((c) => {
            const tmp = document.createElement("input");
            tmp.type = "hidden";
            tmp.name = "newsletter_accept[]";
            tmp.value = c.value;
            return tmp;
          });

        this.valuesContainerTarget.innerHTML = "";
        inputs.forEach((node) => this.valuesContainerTarget.appendChild(node));
      });
    });
  }
}
