// For those wondering... BAC stands for BrandAuthConfig 😜

import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly customAttributeTarget!: HTMLInputElement;

  @Value(String) readonly listValue!: string;
  private requiredCheckbox: HTMLInputElement;
  private customAttributeType: HTMLSelectElement;
  private canBeRequiredList: number[] = [];

  connect() {
    this.requiredCheckbox = document.querySelector<HTMLInputElement>("#brand_auth_config_query_field_required")!;
    this.customAttributeType = document.querySelector<HTMLSelectElement>("#attribute_type")!;

    if (this.requiredCheckbox && this.customAttributeType) {
      this.canBeRequiredList = JSON.parse(this.listValue);

      this.onCustomAttributeChange({ target: { value: this.customAttributeTarget.value } });
      this.customAttributeTarget.addEventListener("change", this.onCustomAttributeChange);
    }
  }

  private onCustomAttributeChange = (event: any) => {
    if (this.customAttributeType.value === "user_attribute") {
      this.requiredCheckbox.disabled = false;
      return;
    }

    const canBeRequired = this.canBeRequiredList.includes(Number(event.target.value));
    if (canBeRequired) {
      this.requiredCheckbox.disabled = false;
    } else {
      this.requiredCheckbox.disabled = true;
      this.requiredCheckbox.checked = false;
    }
  };
}
