import { Targets, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import { toggleDisableField } from "~/lib/toggleDisabledField";

@TypedController
export default class extends Controller {
  @Targets readonly selectContainerTargets: HTMLDivElement[];

  connect() {
    if (!this.newsletterSelectField) {
      this.element.innerHTML = '<h1 class="tw-red-500">Error loading newsletter</h1>';
      return;
    }

    this.newsletterSelectField.addEventListener("change", this.handleChange.bind(this));
  }

  handleChange(event: Event) {
    const newNewsletterId = (event.target as HTMLSelectElement).value;
    if (!newNewsletterId) {
      return;
    }

    this.selectContainerTargets.forEach((container) => {
      const select = container.querySelector<"select">("select");
      const enabled = container.dataset.newsletterId === newNewsletterId;

      container.classList[enabled ? "remove" : "add"]("tw-hidden");
      select && toggleDisableField(select, enabled);
    });
  }

  get newsletterSelectField() {
    return document.querySelector("select#newsletter_subscription_newsletter_id") as HTMLSelectElement;
  }
}
