import { Target, Targets, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly checkboxTarget: HTMLInputElement;
  @Targets readonly inputsTargets: HTMLInputElement[];
  @Targets readonly labelsTargets: HTMLLabelElement[];

  connect() {
    this.checkboxTarget.addEventListener("change", this.toggleInputs.bind(this));
    this.toggleInputs();
  }

  toggleInputs() {
    this.labelsTargets.forEach((label) => label.classList.toggle("text-gray-300", !this.checkboxTarget.checked));
    this.inputsTargets.forEach((input) => {
      input.disabled = !this.checkboxTarget.checked;

      if (this.checkboxTarget.checked && input.dataset.shouldCheck === "true") {
        input.checked = true;
        input.dataset.shouldCheck = undefined;
      }
    });
  }
}
