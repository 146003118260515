import { Target, Targets, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Targets elementTargets: (HTMLInputElement | HTMLSelectElement)[];

  @Target submitterTarget: HTMLInputElement | null;
  declare readonly hasSubmitterTarget: boolean;

  connect() {
    this.elementTargets.forEach((elem) => {
      if (!["select", "checkbox"].includes(elem.type)) {
        console.error("This controller doesnt work on textarea/input because it submits without any debounce on any change");
        return;
      }

      elem.addEventListener("change", () => {
        const submitter = this.hasSubmitterTarget ? this.submitterTarget : null;
        elem.form!.requestSubmit(submitter); // it should fail if form is null

        // TODO: This will technically break for turbo use-cases since we don't want to disable all but fix it then
        this.elementTargets.forEach((e) => e.setAttribute("disabled", "disabled"));
      });
    });
  }
}
