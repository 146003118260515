import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDivElement> {
  // technically this is a number
  timeout: NodeJS.Timeout | null = null;

  @Value(Number) readonly removalTimeoutValue!: number;

  connect(): void {
    this.timeout = setTimeout(this.remove.bind(this), this.removalTimeoutValue);

    this.element.addEventListener("mouseenter", () => this.timeout && clearTimeout(this.timeout));
    this.element.addEventListener("mouseleave", this.queueRemoval);
  }

  queueRemoval = () => {
    this.timeout = setTimeout(this.remove, this.removalTimeoutValue);
  };

  remove() {
    this.element.style.opacity = "0";
    setTimeout(() => this.element.parentNode?.removeChild(this.element), 500);
  }
}
