import { TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDivElement> {
  @Value(Boolean) closeOnBackdropClickValue = true;
  @Value(Boolean) openOnLoadValue = false;

  connect() {
    if (this.openOnLoadValue) {
      this.openModal();
    }
  }

  openModal() {
    this.backdrop.classList.remove("tw-hidden");
    this.element.style.display = "flex";
    document.body.style.overflow = "hidden";

    if (this.closeOnBackdropClickValue) {
      this.backdrop.addEventListener("click", this.closeModal.bind(this));
    }
  }

  closeModal() {
    this.backdrop.classList.add("tw-hidden");
    this.element.style.display = "none";
    document.body.style.overflow = "auto";

    if (this.closeOnBackdropClickValue) {
      this.backdrop.removeEventListener("click", this.closeModal.bind(this));
    }
  }

  // This is a global element
  private get backdrop() {
    const tmp = document.querySelector<HTMLDivElement>("#modal-overlay");
    if (!tmp) {
      throw new Error("Backdrop not found"); // TODO: log this with sentry
    }

    return tmp;
  }
}
