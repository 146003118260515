import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly remainingTimeTarget?: HTMLElement;

  @Value(String) redirectUrlValue: string;
  @Value(Number) durationValue = 3000;

  connect() {
    super.connect();
    let time = Math.floor(Math.round(this.durationValue / 100) / 10);

    this.updateRemainingTime(time);
    time--;

    const int = setInterval(() => {
      this.updateRemainingTime(time);
      time--;

      if (time <= 0) {
        clearInterval(int);
        window.location.href = this.redirectUrlValue;
      }
    }, 1000);
  }

  private updateRemainingTime(time: number) {
    if (!this.remainingTimeTarget) return;

    this.remainingTimeTarget.innerText = time.toString();
  }
}
