type RecursiveRecord<T> = {
  [K: string]: T | RecursiveRecord<T>;
};

export class PassedTranslations {
  private readonly translations: RecursiveRecord<string>;

  constructor(translations: string) {
    try {
      this.translations = JSON.parse(translations);
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error(e);
      }

      this.translations = {};
    }
  }

  public t(key: string, params = {}): any {
    const path = key.split(".");
    const value = path.reduce((translations, key) => translations[key], this.translations);

    if (!value) {
      return null;
    }

    if (typeof value === "string") {
      return value?.replace(/%\{(\w+)\}/g, (_, key) => params[key]);
    }

    return value;
  }
}
