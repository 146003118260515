import { Target, TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly resendAgainTextTarget: HTMLInputElement;
  @Target readonly resendAgainButtonTarget: HTMLButtonElement;

  private declare readonly hasResendAgainTextTarget: boolean;

  connect() {
    if (!this.hasResendAgainTextTarget) {
      return;
    }

    setTimeout(() => {
      this.resendAgainButtonTarget.disabled = false;
      this.resendAgainTextTarget.classList.remove("tw-opacity-40");
    }, 10000);
  }
}
