import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import intlTelInput from "intl-tel-input";
import { Controller } from "stimulus";
import { PassedTranslations } from "~/lib/passedTranslations";

import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils.js";
import "./component.scss";

@TypedController
export default class extends Controller {
  @Target readonly phoneFieldTarget!: HTMLInputElement;

  @Value(Boolean) readonly countrySelectEnabledValue: boolean;
  @Value(String) readonly defaultCountryCodeValue: string;
  @Value(String) translationsValue!: string;

  private intlInstance: any;
  private translations: PassedTranslations;

  connect(): void {
    if (!this.countrySelectEnabledValue) {
      return;
    }

    this.translations = new PassedTranslations(this.translationsValue);

    this.intlInstance = intlTelInput(this.phoneFieldTarget, {
      nationalMode: false,
      formatOnDisplay: true,
      autoPlaceholder: "aggressive",
      showSelectedDialCode: true,
      i18n: {
        ...Object.fromEntries(this.translations.t("countriesTranslations").map(([key, value]) => [key.toLowerCase(), value])),
        ...this.translations.t("countrySelectFieldTranslations"),
      },
      initialCountry: this.defaultCountryCodeValue?.toLowerCase() || "",
      hiddenInput: () => ({
        phone: this.phoneFieldTarget.name,
        country: "user[phone_country_code]",
      }),
      utilsScript: "/intl-tel-input/build/js/utils.js",
    });

    // TODO remove this part once we refactor profile & get rid of old js
    document.querySelector("input.iti__search-input")?.classList.add("no-floating-label");

    this.phoneFieldTarget.addEventListener("countrychange", this.handleCountryChange.bind(this));
    this.phoneFieldTarget.addEventListener("input", this.handleInputChange.bind(this));
    this.phoneFieldTarget.addEventListener("keyup", this.handleKeyUp.bind(this));
    this.phoneFieldTarget.addEventListener("paste", this.handlePaste.bind(this));
    this.phoneFieldTarget.form?.addEventListener("submit", this.handleSubmit.bind(this));
  }

  handleCountryChange() {
    const countryData = this.intlInstance.getSelectedCountryData();

    if (countryData.dialCode && this.phoneFieldTarget.value.includes(`+${countryData.dialCode}`)) {
      this.phoneFieldTarget.value = this.phoneFieldTarget.value.replace(`+${countryData.dialCode}`, "");
      return;
    }

    this.validatePhone();
  }

  handleInputChange() {
    const defaultValue = this.phoneFieldTarget.defaultValue || this.phoneFieldTarget.placeholder;

    if (this.phoneFieldTarget.value && this.phoneFieldTarget.value !== defaultValue) {
      // user enterd a phone number --> could be autofilled or manually entered
      // set number to format for autofilled numbers
      this.intlInstance.setNumber(this.phoneFieldTarget.value);
      this.handleSubmit;
    }

    this.clearHTMLValidation();
    const submitButton = this.phoneFieldTarget.form?.querySelector("input[type=submit]") as HTMLInputElement;
    if (submitButton) {
      submitButton.disabled = false;
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === "Backspace" && this.phoneFieldTarget.value === "") {
      this.intlInstance.setCountry("");
    }
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    this.intlInstance.setNumber(event.clipboardData?.getData("text") || "");
  }

  handleSubmit(event: Event) {
    if (this.phoneFieldTarget.value === "") {
      this.clearHTMLValidation();
      return;
    }

    event.preventDefault();
    if (this.validatePhone()) {
      this.phoneFieldTarget.form?.submit();
    } else {
      this.phoneFieldTarget.focus();
      this.phoneFieldTarget.classList.add("focus:tw-border-red-600");
      this.setHTMLValidation("Invalid number");
    }
  }

  validatePhone() {
    let regex = new RegExp("^(?=.{4,13}$)[\\d\\ ]+$");
    let match = regex.test(this.phoneFieldTarget.value);
    return match && this.intlInstance.getSelectedCountryData().dialCode;
  }

  clearHTMLValidation() {
    this.phoneFieldTarget.setCustomValidity("");
    this.phoneFieldTarget.reportValidity();
  }

  setHTMLValidation(customText: string) {
    this.phoneFieldTarget.setCustomValidity(customText);
    this.phoneFieldTarget.reportValidity();
  }
}
